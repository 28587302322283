.menu-background {
    background-color: var(--blue);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
}

.burger-close {
    cursor: pointer;
    position: fixed;
    top: 16px;
    left: 36px;
    height: 25px;
}

.menu-link {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    color: var(--lavender);
}

.menu-link:hover {
    text-decoration: none;
    color: var(--lavender);
}

.menu-row {
    margin-bottom: 8px;
}

@media only screen and (max-width: 540px) {
    .burger-close {
        left: 15px;
        height: 35px;
        top: 12.5px;
    }

    .menu-link {
        font-size: 25px;
    }

    .menu-row {
        margin-bottom: 22px;
    }
}