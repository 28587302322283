/* Cities list page header */

div#header-container {
    display: flex;
    width: 100vw;
    padding-top: 26px;
    justify-content: space-between;
}

#header-arrow-back {
    margin-left: 2vw;
    margin-top: 7px;
    height: 18px;
    width: auto;
}

h1#header-title {
    color: var(--lavender);
    text-transform: uppercase;
    font-size: 27px;
}

div#header-map-icon {
    margin-right: 2vw;
    margin-top: 4px;
}

#header-map-img {
    height: 29px;
    width: auto;
}

p#header-map {
    display: inline-block;
    font-size: 16px;
    padding-left: 5px;
    color: var(--lavender);
}


/* ================================ */

/* Cities list page */

div#cities-bg {
    background-color: var(--blue);
    color: var(--lavender);
    min-height: 100vh;
}

div#sidenav {
    max-height: 100vh;
    width: 5vw;
    position: fixed;
    top: 0;
    right: 5vw;
    overflow-x: hidden;
}

div#sidenav a.sidenav-link {
    display: block;
    text-transform: lowercase;
    font-weight: 600;
    color: var(--lavender);
}

div#sidenav a.sidenav-link:hover {
    text-decoration: none;
}

div#sidenav a.sidenav-disabled {
    opacity: .6;
    pointer-events: none;
}

ul#cities-list {
    text-align: left;
    list-style-type: none;
}

.city-name {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--lavender);
}

.city-name:hover {
    color: var(--lavender);
}

.city-name:focus-visible {
    outline-color: var(--blue);
}

/* ================================ */

/* City shops page */

ul#city-shops-list {
    margin-bottom: 0;
}

li.city-shops-items {
    text-align: left;
    padding: 16px 0 16px 20px;
    list-style: none;
    margin-left: calc(50vw - 140px);
}

.shops-image {
    object-fit: cover;
    height: 100px;
    width: 131px;
    margin-right: 25px;
}

.shops-name {
    display: inline-block;
}

a.shops-name {
    text-decoration: underline;
    color: inherit;
    font-weight: 600;
    font-size: 18px;
}

a.shops-name:hover {
    text-decoration: none;
    color: inherit;
}

/* ================================ */

/* Single shop page */

div#shop-single-page {
    margin: 40px 24% 0px 24%;
    padding-bottom: 50px;
}

div#shop-pin-div {
    display: inline-block;
}

div#shop-header-div {
    display:flex;
    justify-content: center;
    /* justify-content: space-around; */
}

div#shop-header-text {
    display: inline-block;
    text-align: left;
    padding-left: 10px;
}

.single-shop-pin {
    height: 40px;
    width: auto;
}

img.shop-image {
    object-fit: cover;
    width: 100%;
    height: 700px;
}

.shop-audiences-p {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
}

div.shop-wrapper {
    display: flex;
    margin: 25px 0;
    align-items: center;
}

img.shop-nav {
    height: 40px;
    width: auto;
    padding-right: 20px;
}

a.shop-link {
    text-decoration: none;
    color: inherit;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
}

a.shop-link:hover {
    color: inherit;
}

div.shop-section {
    margin: 25px 10px 0 10px;
}

p.shop-section-about {
    text-align: left;
    margin: 0;
}

/* ================================ */


@media only screen and (max-width: 540px) {

    /* Cities list page header */

    div#header-container {
        padding-top: 26px;
    }
    
    #header-arrow-back {
        margin-left: 9vw;
        margin-top: 7px;
        height: 18px;
        width: auto;
    }
    
    h1#header-title {
        font-size: 27px;
    }
    
    div#header-map-icon {
        margin-right: 10vw;
        margin-top: 4px;
    }
    
    #header-map-img {
        height: 29px;
        width: auto;
    }
    
    p#header-map {
        font-size: 16px;
        font-weight: 600;
        padding-left: 5px;
    }

    /* Cities list page */

    div#sidenav {
        width: 5vw;
        top: calc(70.61px + 6vh);
        right: 11vw;
    }

    div#sidenav a.sidenav-link {
        font-size: 13px;
        line-height: 19px;
    }

    ul#cities-list {
        padding-left: 18vw;
        padding-top: 5.6vh;
    }

    .city-name {
        font-size: 25px;
        line-height: 46px;
    }

    /* ============================ */

    /* City shops page */

    li.city-shops-items {
        margin-left: 0;
    }

    .shops-image {
        height: 80px;
        width: 105px;
        margin-right: 25px;
    }

    a.shops-name {
        text-decoration: underline;
        color: inherit;
        font-weight: 600;
        font-size: 18px;
    }

    a.shops-name:hover {
        text-decoration: none;
        color: inherit;
    }

    /* ============================ */

    /* Single shop page */

    div#shop-single-page {
        margin: 40px 7% 0px 7%;
        padding-bottom: 25px;
    }

    div#shop-header-div {
        margin-bottom: 10px;
    }

    div#shop-pin-div {
        padding-right: 5%;
    }

    .single-shop-pin {
        height: 60px;
        width: auto;
    }

    h1#shop-header-name {
        text-transform: uppercase;
        font-size: 25px;
    }

    h2#shop-header-location {
        text-transform: capitalize;
        font-size: 20px;
    }

    h2#shop-header-description {
        text-transform: uppercase;
        font-size: 20px;
    }

    div#shop-carousel {
        width: 100%;
        height: auto;
    }

    .carousel-item {
        height: 100%;
    }

    img.shop-image {
        object-fit: cover;
        width: 100%;
        height: 230px;
    }

    .shop-audiences-p {
        font-size: 13.5px;
        text-transform: uppercase;
        font-weight: 600;
    }

    div.shop-section {
        margin: 25px 10px 0 10px;
    }

    h3.shop-section-header {
        font-size: 18px;
        text-align: left;
        font-weight: 600;
    }

    p.shop-section-about {
        text-align: left;
    }

    div.shop-wrapper {
        margin: 8px 0;
    }

    img.shop-nav {
        height: 20px;
        width: auto;
        padding-right: 12px;
    }

    p.shop-hours {
        margin-bottom: 0;
    }

    a.shop-link {
        text-decoration: none;
        color: inherit;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }

    a.shop-link:hover {
        color: inherit;
    }
    /* ============================ */
}