#contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

#about, #team, #sustainability, #community, #contacts {
    padding: 60px 310px;
}

#about, #sustainability, #contacts {
    background-color: var(--blue);
    color: var(--lavender);
}

#team, #community {
    color: var(--blue);
}

#about > *, #sustainability > *, #community > *, #contacts > * {
    margin-bottom: 20px;
}

#team > h1, h2, .section-p {
    margin-bottom: 20px;
}

h1.section-header {
    text-transform: uppercase;
    font-size: 25px;
}

h2.section-h2 {
    font-size: 20px;
    font-weight: 200;
    font-style: italic;
}

.row-pin {
    margin-bottom: 20px;
}

.col-sustainable {
    text-align: left;
}

.img-sustainable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
}

p.pin-p {
    font-size: 20px;
    margin-bottom: 0;
}

img.section-pin {
    height: 35px;
    margin-right: 5px;
}

p.section-p {
    font-size: 20px;
    text-align: left;
}

p.center-p {
    font-size: 20px;
}

span.section-span, .bold-span {
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    text-decoration: none;
}

span.bold-span {
    font-style: normal;
    font-weight: 600;
}

.section-img {
    height: 11px;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 2px;
}

.contact-img {
    height: 30px;
}

.contact-col {
    padding: 5px;
}

a.contact-mail {
    font-size: 20px;
    font-weight: 500;
    text-decoration: underline;
    color: white;
}

.burger-icon {
    position: fixed;
    top: 0;
    right: 15px;
    height: 35px;
    margin: 12.5px 11px;
    cursor: pointer;
}

.close-cross {
    cursor: pointer;
    position: fixed;
    top: 16px;
    left: 36px;
    height: 25px;
}

@media only screen and (max-width: 540px) {
    #about, #team, #sustainability, #community, #contacts {
        padding: 60px 70px;
    }

    .close-cross {
        left: 15px;
        height: 35px;
        top: 12.5px;
    }

    .burger-icon {
        height: 45px;
        margin: 12.5px 0;
    }

    h1.section-header {
        font-size: 25px;
    }

    img.section-pin {
        height: 70px;
        margin-right: 0;
    }

    .img-sustainable {
        padding-left: 0;
    }

    .contact-col {
        padding: 10px;
    }

   .contact-img {
       height: 45px;
   }
}