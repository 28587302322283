.cs-logo {
    height: 50px;
    width: 53.45px;
    margin: 5px 0;
}

.active {
    cursor: pointer;
}

.search {
    width: 100%;
    margin: 10px 11px;
    max-width: 400px;
    z-index: 10;
    color: var(--blue);
    text-align: left;
}

.search input {
    padding: 0.5rem;
    font-size: 20px;
    width: 100%;
    height: 36px;
    border: none;
    outline: none;
    background-color: transparent !important;
    color: rgba(21, 42, 252, 0.5);
    z-index: 1;
    text-transform: uppercase;
}

.search-input::placeholder {
    color: rgba(21, 42, 252, 0.5);
    font-size: 20px;
}

.search-img, .menu-icon {
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.menu-col {
    text-align: right;
}

.menu-icon {
    margin: 10px 11px 15px 11px;
}

#autocomplete-popover {
    border-radius: 20px;
    border: none;
}

.autocomplete-element {
    text-transform: uppercase;
    background-color: #e1e1eb;
    color: var(--blue);
    text-align: left;
}

.autocomplete-element:first-child {
    border-radius: 20px 20px 0 0;
}

.autocomplete-element:last-child {
    border-radius: 0 0 20px 20px;
    padding-bottom: 7px;
}

@media only screen and (max-width: 540px) {
    #cs-logo-disabled {
        display: none;
    }

    .search {
        margin-top: 15px;
        margin-left: 0;
    }

    .search-col {
        padding-right: 0;
    }

    .menu-col {
        padding-left: 0;
    }

    .logo-col {
        padding: 0;
    }

    .search input {
        padding-left: 0;
        font-size: 22px;
        height: 45px;
    }

    .search-input::placeholder {
        font-size: 22px;
        font-weight: 400;
    }

    .search-img {
        height: 45px;
    }

    .cs-logo {
        height: 60px;
        margin: 6px 0 4px 0;
    }

    .menu-icon {
        margin-right: 0;
        height: 45px;
    }
}