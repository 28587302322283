/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --blue: #152afc;
  --lavender: #dadbfe;
  --brown: #959ba2;
  --white: #fffffa;
  --black: #000103;
  --orange: #fa8334;
  --red: #ff312e;
}

html {
  box-sizing: border-box;
  font-family: aktiv-grotesk, sans-serif;
  font-style: normal;
  font-weight: 200;
  text-align: center;
}

body {
  background-color: var(--lavender);
  height: 100%;
  width: 100%;
  /* color: var(--blue); */
  font-family: aktiv-grotesk-condensed, sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* *,
*:before,
*:after {
  box-sizing: inherit;
} */