.add-background {
    background-color: rgba(218, 219, 254, .85);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
}

.add-community {
    background-color: var(--lavender);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
}

.add-disabled {
    display: none;
}

.close-add-commu {
    cursor: pointer;
    position: fixed;
    top: 16px;
    left: 36px;
    height: 25px;
}

.add-header, .add-span {
    color: var(--blue);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}

.add-header {
    line-height: 25px;
    letter-spacing: 0.5px;
}

span {
    text-decoration: underline;
}

.form-div {
    z-index: 100;
    position: relative;
    width: 20%;
    left: 40%;
}

.store-col {
    padding: 0 25px;
}

#FormCheckboxRange {
    cursor: pointer;
    background-color: var(--blue);
    margin-bottom: 14px;
}

#FormCheckboxRange:focus::placeholder {
    color: white;
    text-align: center;
    padding-left: .45rem;
}

#FormCheckboxRange:focus {
    color: transparent;
    background-color: var(--blue);
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

#FormCheckboxRangeActive {
    cursor: pointer;
    background-color: white; 
    margin-bottom: 7px;   
}

#FormCheckboxRangeActive::placeholder {
    color: var(--blue);
    text-align: center;
    padding-left: .45rem;
}

.add-store-form {
    text-align: center;
    background-color: var(--blue);
    color: var(--lavender);   
    border-radius: 80px;
    padding: 15px .8rem;
    height: 1.75rem;
    font-size: 18px;
    letter-spacing: 0.3px;
    font-weight: 300;
    border: 0;
}

.add-store-form::placeholder {
    text-align: center;
    color: white;
    padding-left: .45rem;
}

.add-store-form:focus {
    text-align: left;
    background-color: white;
    color: #495057;
    box-shadow: none;
}

.add-store-form:focus::placeholder {
    text-align: left;
    color: #495057;
    padding-left: 0;
}

.add-form-row {
    margin-bottom: 14px;
}

.inline-checkbox > .form-check-label{
    text-transform: uppercase;
    color: var(--blue);
    font-size: 9px;
    font-weight: 520;
}

.inline-checkbox > .form-check-input {
    height: 9px;
    margin-right: 2px;
}

.inline-checkbox {
    margin-right: 4px;
}

.submit-col {
    padding: 0 20px;
    margin-top: 40px;
}

.submit-col-active {
    padding: 0 20px;
    margin-top: 16px;
}

.btn-primary {
    background-color: var(--blue);
    border-radius: 80px;
    border-color: var(--blue);
    font-weight: 600;
    font-size: 20px;
    padding: 0;
    transition: none;
} 

.btn-primary:focus, .btn-primary:hover {
    background-color: var(--blue);
    border-color: var(--blue);
}

.btn-primary:disabled {
    background-color: var(--blue);
    border-color: var(--blue);
    opacity: .6;
}

.checkbox-col {
    margin-bottom: 7px;
}

.checkbox-btn {
    padding: 0 0 0 0;
    height: 1.75rem;
    color: #fefeff;
    font-size: 20px;
    font-weight: 300;
}

@media only screen and (max-width: 540px) {
    #FormCheckboxRange {
        margin-bottom: 22px;
    }

    #FormCheckboxRangeActive {
        margin-bottom: 11px;
    }

    .submit-col {
        margin-top: 70px;
    }

    .submit-col-active {
        margin-top: 45.5px;
    }

    .form-div {
        width: 80%;
        left: 10%;
    }

    .close-add-commu {
        left: 15px;
        height: 35px;
        top: 12.5px;
    }

    .add-header-row {
        margin-bottom: 35px;
    }

    .add-header, .add-span {
        font-size: 25px;
    }

    .thanks-header {
        line-height: 32px;
    }

    .add-store-form {
        font-size: 22px;
        padding: 25px .8rem;
    }

    .add-store-form:focus {
        padding-left: 18px;
    }

    .add-form-row {
        margin-bottom: 22px;
    }

    .checkbox-col {
        padding: 0;
        margin-bottom: 11px;
    }

    .inline-checkbox > .form-check-label{
        font-size: 15px;
        font-weight: 400;
    }
    
    .inline-checkbox > .form-check-input {
        height: 15px;
        margin-right: 1px;
    }
    
    .inline-checkbox {
        margin-right: 6px;
    }

    .btn-primary {
        font-size: 25px;
        padding: 5.5px 0;
    }
}

.add-store-form:-webkit-autofill {
    -webkit-text-fill-color: var(--lavender) !important;
    -webkit-box-shadow: 0 0 0px 1000px var(--blue) inset;
}

.add-store-form:-webkit-autofill:focus {
    -webkit-text-fill-color: #495057 !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.add-store-form:-webkit-autofill::first-line {
    font-family: aktiv-grotesk-condensed, sans-serif;
}