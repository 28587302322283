#not-found-div {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    color: var(--blue);
    font-family: aktiv-grotesk-condensed, sans-serif;
}

#not-found-logo {
    height: 60px;
    width: auto;
    margin-bottom: 29px;
}

.not-found-text {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 3px;
}

.not-found-btn {
    margin-top: 35px;
    background-color: var(--blue);
    color: white;
    font-size: 16px;
    font-weight: 400;
    height: 32px;
    padding: 0 12px;
    border-radius: 25px;
    border: 1px solid var(--blue);
}

.hidden-row-404 {
    height: 120px;
}

@media only screen and (max-width: 540px) {

    .not-found-text {
        margin-bottom: 0;
        margin-top: 11px;
    }

    .not-found-btn {
        margin-top: 15px;
    }

    .hidden-row-404 {
        height: 80px;
    }
}