.team-img {
    height: 240px;
    margin: 0;
}

.row-team {
    padding: 0 50px;
}

.row-team > .col {
    padding: 0;
}

.team-p {
    text-align: left;
    padding: 5px;
    margin-bottom: 10px;
}