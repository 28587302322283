.sign-up-logo {
    height: 60px;
    width: auto;
    margin-bottom: 29px;
}

#signup-title {
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    color: var(--blue);  
    margin-bottom: 25px; 
}

#inlineFormInputEmail {
    display: inline-block;
    text-align: center;
    width: 137px;
    height: 32px;
    border-radius: 25px 0 0 25px;
    color: var(--lavender);
    background-color: var(--blue);
    border: none;
    border-right: 1px solid var(--lavender);
}

#inlineFormInputEmail:focus {
    text-align: left;
    background-color: white;
    color: #495057;
    box-shadow: none;
}

#inlineFormInputEmail::placeholder {
    font-size: 13px;
    padding-left: 14px;
    text-align: center;
    color: white;
}

#inlineFormInputEmail:focus::placeholder {
    text-align: left;
    color: #495057;
    padding-left: 0;
}

#signup-button {
    display: inline-block;
    width: 46px;
    height: 32px;
    font-size: 10px;
    border-radius: 0 25px 25px 0;
    padding-top: 2px;
    vertical-align: top;
    background-color: var(--blue);
    color: white;
    font-weight: 600;
    border: none;
}

#signup-button:disabled {
    opacity: .6;
}

#some-div {
    margin-top: 25px;
}

.signup-some {
    cursor: pointer;
    height: 22px;
    width: auto;
}

#hidden-row {
    height: 80px;
}

@media only screen and (max-width: 540px) {
    .sign-up-logo {
        height: 85px;
        margin-bottom: 40px;
    }

    #signup-title {
        font-size: 22px;
        line-height: 30px;
        font-weight: 300;
        margin-bottom: 40px;
    }

    #inlineFormInputEmail {
        width: 60vw;
        height: 50px;
        font-size: 20px;
    }

    #inlineFormInputEmail::placeholder {
        font-size: 20px;
        font-weight: 300;
    }

    #signup-button {
        width: 20vw;
        height: 50px;
        font-size: 15px;
        border-left: 1px solid var(--lavender);
    }

    #some-div {
        margin-top: 80px;
    }
    
    .signup-some {
        height: 36px;
    }
}