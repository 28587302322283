.help-background {
    background-color: rgba(21, 42, 252, .85);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
}

.help-disabled {
    display: none;
}

.help-header, .help-span {
    color: var(--lavender);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}

span {
    text-decoration: underline;
}

.help-pin {
    margin-left: 40px;
    padding-right: 0;
    text-align: right;
}

.align-left {
    text-align: left;
    padding-left: 4px;
}

.help-img {
    height: 50px;
    margin-right: 5px;
}

p.help-text {
    display: inline-block;
    text-transform: uppercase;
    color: var(--lavender);
    font-weight: 500;
    margin-bottom: 0;
}

@media only screen and (max-width: 540px) {
    .help-pin {
        margin-left: 0;
    }

    .help-row {
        margin-bottom: 15px;
    }

    .help-header-row {
        margin-bottom: 30px;
    }

    .help-header, .help-span {
        font-size: 25px;
    }

    .help-img {
        height: 70px;
    }

    .help-text {
        font-size: 18px;
    }
}