.community-link {
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
    color: var(--blue);
}

.community-link:hover {
    color: var(--blue);
}

.community-list {
    padding: 0 110px;
}

@media only screen and (max-width: 540px) {
    .community-list {
        padding: 0 20px;
    }
}