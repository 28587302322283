div:focus {
    outline: none !important;
}

#info-blue {
    width: 208px;
    padding: 10px 15px;
    border-radius: 15px;
    text-align: left;
    background-color: var(--blue);
    color: var(--lavender);
}

#info-lavender {
    width: 208px;
    padding: 10px 15px;
    border-radius: 15px;
    text-align: left;
    background-color: var(--lavender);
    color: var(--blue);
}

#info-brown {
    width: 208px;
    padding: 10px 15px;
    border-radius: 15px;
    text-align: left;
    background-color: var(--brown);
    color: var(--lavender);
}

.gm-style-iw-t {
    bottom: 44px !important;
}

div#info-after-blue {
    height: 0;
    width: 0;
    position: relative;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid var(--blue);
    left: 50%;
    margin-left: -10px;
}

div#info-after-lavender {
    height: 0;
    width: 0;
    position: relative;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid var(--lavender);
    left: 50%;
    margin-left: -10px;
}

div#info-after-brown {
    height: 0;
    width: 0;
    position: relative;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid var(--brown);
    left: 50%;
    margin-left: -10px;
}

#pop-up-close {
    object-fit: cover;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;
}

#store-name {
    text-transform: uppercase;
    font-size: 20px;
    width: 156px;
    margin-bottom: 2px;
}

h2#store-location {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
}

#store-description {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 5px;
}

#store-carousel {
    width: 100%;
}

div.store-wrapper {
    display: flex;
    align-items: center;
    margin: 4px 0;
}

div.hours-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

img.pop-up-nav {
    height: 23px;
    margin-right: 5px;
    margin-left: 5px;
}

.pop-up-link {
    text-decoration: none;
    color: inherit;
    font-weight: 600;
    font-size: 11px;
}

.pop-up-link:hover {
    color: inherit;
}

.carousel-item {
    height: 100%;
}

.carousel-indicators li {
    visibility: hidden;
}

.carousel-control-prev-icon {
    background-image: url(../img/nav/SliderBlueL.svg);
}
 
.carousel-control-next-icon {
    background-image: url(../img/nav/SliderBlueR.svg);
}

#info-brown .carousel-control-prev-icon {
    background-image: url(../img/nav/SliderLavenderL.svg);
}
 
#info-brown .carousel-control-next-icon {
    background-image: url(../img/nav/SliderLavenderR.svg);
}

.carousel-control-prev, .carousel-control-next {
    opacity: .8;
}

.carousel-control-prev:hover, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-next:focus {
    opacity: .8;
}

img.store-image {
    height: 130px;
    width: 100%;
    margin-bottom: 12px;
}

p.pop-up-address {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 0;
}

p.hours-p {
    display: inline-block;
    text-align: center;
    font-size: 9.5px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

p.audiences-p {
    text-align: center;
    font-size: 9.5px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 16px;
}

div#hours-display {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
}

ul#hours-list {
    list-style: none;
    margin-top: 6px;
    margin-bottom: 0;
}

.hours-arrow {
    width: 25px;
    height: auto;
    cursor: pointer;
}

.hours-arrow-active {
    transform: rotate(180deg);
    width: 25px;
    height: auto;
    cursor: pointer;
}

.list {
    visibility: hidden !important;
    max-height: 0 !important;
}

li.hours-elements {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    max-height: 20px;
    visibility: visible;
    transition: max-height 0.5s;
}

.pins-cluster div {
    font-family: aktiv-grotesk-condensed, sans-serif !important;
    color: var(--lavender) !important;
    /* top: -11px !important; */
}

@media only screen and (max-width: 540px) {
    #info-blue, #info-lavender, #info-brown {
        width: 70vw;
        padding: 25px 15px 20px 15px;
    }

    #pop-up-close {
        height: 35px;
        width: 35px;
        top: 2px;
        right: 4px;
    }

    h1#store-name {
        width: 63.2vw;
        /* max-height: 75px; */
        font-size: 35px;
    }

    h2#store-location {
        font-size: 25px;
        margin-bottom: 5px;
    }

    h2#store-description {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .gm-style-iw {
        max-width: 75vw !important;
    }

    .gm-style-iw-t {
        bottom: 54px !important;
    }

    img.store-image {
        height: 170px;
        margin-bottom: 15px;
    }

    img.pop-up-nav {
        height: 30px;
    }

    p.hours-p, p.audiences-p {
        font-size: 13px;
    }

    .pop-up-link {
        font-size: 16px;
    }

    li.hours-elements {
        font-size: 12px;
    }

    div.store-wrapper {
        margin: 8px 0;
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
        visibility: hidden;
    }

    .carousel-indicators li {
        visibility: visible;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        border: 1.5px solid var(--blue);
        opacity: 1;
        margin-bottom: 5px;
    }
    
    .carousel-indicators li.active {
        background-color: var(--blue);
    }
    
    #info-brown .carousel-indicators li {
        border: 1.5px solid var(--brown);
    }
    
    #info-brown .carousel-indicators li.active {
        background-color: var(--brown);
    }
}