.team-row-mobile {
    margin-bottom: 20px;
}

.team-img-mobile {
    width: 100%;
    height: auto;
    margin-right: 0;
}

.h1-test {
    background-color: rgba(218, 219, 254, .85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.p-visible {
    background-color: rgba(218, 219, 254, .85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 35px;
}

#p-disabled {
    visibility: hidden;
}

.team-p {
    text-align: center;
    line-height: 25px;
}

.team-mobile-span {
    text-decoration: none;
    font-weight: 600;
}

