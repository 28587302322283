.map-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #152afc;
    color: white;
    font-weight: bold;
    transition: 200ms;
    text-decoration: none;
    position: absolute;
    bottom: 80px;
    right: 26px;
    border: none;
    font-size: 25px;
    outline: none;
}

.btn-top {
  top: 80px;
}

.map-btn:hover {
    color: #666;
    cursor: pointer;
  }

.map-btn:focus {
    outline: none;
  }

.map-btn-img {
  vertical-align: inherit;
}

@media only screen and (max-width: 540px) {
    .map-btn {
        right: 10px;
        width: 60px;
        height: 60px;
        bottom: 120px;
    }

    .btn-top {
      top: 100px;
    }

    .map-btn-img {
      width: 60px;
      height: auto;
    }
}
