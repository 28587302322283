h1 {
    font-family: 'aktiv-grotesk-condensed', sans-serif;
    font-weight: 700;
    font-style: normal;
}

p {
    font-family: aktiv-grotesk-condensed, sans-serif;
    font-weight: 300;
    font-style: normal;
}

* {
    padding: 0;
    margin: 0;
    font-family: aktiv-grotesk-condensed, sans-serif;
    font-weight: 200;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}


/*google maps api styles*/

.gm-style-iw-d {
    overflow: visible !important;
}


/* google maps close button hide*/

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style .gm-style-iw-c {
    padding: 0px !important;
    overflow: visible !important;
    background-color: transparent;
    box-shadow: none;
}

.gm-style .gm-style-iw-t::after {
    display: none !important;
}

#sign-up-background {
    background-color: rgba(218, 219, 254, .85);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
}

#lavender-square {
    position: fixed;
    top: 7px;
    left: 25px;
    height: 40px;
    width: 43px;
    background-color: var(--lavender);
}

#signup-close {
    cursor: pointer;
    position: fixed;
    top: 16px;
    left: 25px;
    height: 25px;
    width: auto;
}

#back-to-map {
    cursor: pointer;
    position: fixed;
    top: 16px;
    left: 36px;
    height: 25px;
    width: auto;
}

.hidden-row {
    height: 100px;
}

@media only screen and (max-width: 540px) {
    #signup-close {
        top: 20px;
        left: 15px;
        height: 35px;
    }

    #back-to-map {
        left: 15px;
        height: 35px;
        top: 12.5px;
    }

    #lavender-square {
        left: 5px;
        height: 58px;
        width: 48px;
    }
}